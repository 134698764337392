<template>
  <section>
    <p class="text-h6 primary--text">Techos presupuestarios</p>
    <v-row>
      <v-col cols="12">
        <p class="mt-2">Listado de instituciones</p>
        <DataTableComponent
        :headers="headers"
        @paginar="paginate"
        :items="instituciones"
        v-models:pagina="paginaModel"
        v-models:select="porPaginaModel"
        :total_registros="filters.total_rows"
        >
        <template v-slot:[`item.monto_asignado`]="{ item }">
            {{ item.monto_asignado !== null 
                ? Intl.NumberFormat('en-US', {
                    currency: 'USD', 
                    minimumFractionDigits: 2
                  }).format(item.monto_asignado)
                : "-" 
            }}
          </template>
          <template v-slot:[`item.monto_disponible`]="{ item }">
            {{ item.monto_asignado !== null 
              ? Intl.NumberFormat('en-US', {
                  currency: 'USD',
                  minimumFractionDigits: 2
                }).format(item.monto_disponible)  
              : "-" 
            }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'techos-presupuestarios-unidades',
                    params: { id: item.id },
                  }"
                >
                  <v-icon color="primary">mdi-cog-outline</v-icon>
                </v-btn>
              </template>
              <span>Configuración</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'techos-presupuestarios-asignacion',
                    params: { id: item.id },
                  }"
                >
                  <v-icon color="primary">mdi-currency-usd</v-icon>
                </v-btn>
              </template>
              <span>Asignación de techo</span>
            </v-tooltip>
          </template>
        </DataTableComponent>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataTableComponent from "../../components/DataTableComponent.vue"
export default {
  name: "techos-presupuestarios",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      headers: [
        {
          text: "N°",
          align: "start",
          value: "id",
          sortable: false,
        },
        {
          text: "Institución",
          align: "center",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Monto asignado ($)",
          align: "center",
          value: "monto_asignado",
          sortable: false,
        },
        {
          text: "Monto disponible ($)",
          align: "center",
          value: "monto_disponible",
          sortable: false,
        },
        {
          text: "Acciones",
          align: "center",
          value: "acciones",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("cifradosPresupuestarios", [
      "instituciones",
      "filters2"
    ]),

    porPaginaModel: {
      get() {
        return this.filters2.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters2.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
  },

  methods: {
    ...mapActions("cifradosPresupuestarios", ["getInstituciones"]),

    paginate(params) {
      this.getInstituciones(params);
    },
  },

  created() {
    this.getInstituciones();
  },
};
</script>

<style lang="scss" scoped></style>